@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery {
  &-slide-wrapper {
    background-color: #000;
  }

  &-image {
    height: 70vh !important;

    @media (max-height: 550px) {
      height: 65vh !important;
    }

    @media (max-height: 450px) {
      height: 60vh !important;
    }
  }

  &-thumbnails-container {
    height: 15vh;
  }

  &-content.fullscreen {
    .image-gallery-image {
      height: 85vh !important;

      @media (max-height: 550px) {
        height: 80vh !important;
      }
    }

    .image-gallery-thumbnails-container {
      height: 10vh;

      @media (max-height: 550px) {
        height: 15vh;
      }
    }
  }

  &-thumbnail {
    height: 100%;
    width: initial !important;

    &-inner {
      height: 100%;
    }

    &-image {
      height: 100%;
      width: initial !important;
    }
  }
}

