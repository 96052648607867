.Header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  height: 80px;
  background-color: #e0e0e0;
  z-index: 10;
  transition: background-color 200ms;

  @media (max-width: 1100px) {
    align-items: center;
    justify-content: space-between;
  }

  &--transparent {
    background-color: transparent;

    .Header {
      &__image-wrapper {
        height: 64px;
        padding: 3.5em 1.2em 2.5em;
        background-color: #e0e0e0;

        @media (max-width: 1350px) {
          height: 52px;
        }

        @media (max-width: 1100px) {
          padding: 3.5em 0.8em 2em
        }

        &__image {
          height: 100%;
        }
      }

      &__navigation {
        &__link {
          color: #fff;
          background-color: rgba(0, 0, 0, 0.4);

          @media (max-width: 1100px) {
            color: #000;
            background-color: transparent;
          }

          &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.6);

            @media (max-width: 1100px) {
              color: red;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: transparent;
    transition: padding 400ms, background-color 400ms;

    &__image {
      height: 60%;
      width: auto;
    }
  }

  &__bars {
    cursor: pointer;
    display: none;
    padding: 0 1em;
    font-size: 2em;
    z-index: 5;

    @media (max-width: 1100px) {
      display: block;
    }
  }

  &__navigation {
    display: flex;
    align-self: center;

    @media (max-width: 1100px) {
      position: fixed;
      top: 0;
      right: 0;
      flex-direction: column;
      align-items: flex-end;
      height: 100vh;
      padding: 0.5em 0 0 1em;
      border-left: 1px solid #000;
      background-color: #cdcdcd;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform 200ms;
      z-index: 10;

      &--open {
        transform: translateX(0);
      }
    }

    &__close {
      cursor: pointer;
      display: none;
      justify-content: flex-start;
      width: 100%;
      font-size: 2em;

      @media (max-width: 1100px) {
        display: flex;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      margin-right: 0.5em;
      padding: 0.5em;
      max-width: 250px;
      font-size: 0.9em;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      transition: background-color 200ms, color 200ms;

      @media (max-width: 1350px) {
        font-size: 0.8em;
      }

      @media (max-width: 1100px) {
        max-width: initial;
        margin-top: 1em;
        font-size: 1em;
      }

      &:hover {
        color: #ff0000;
      }

      &--active {
        color: #ff0000;
      }
    }
  }
}
