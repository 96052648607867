.Home {
  .swiper-container {
    position: relative;
    height: 100vh;
  }

  &__slide {
    &__image {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    font-size: 2.5em;
    margin: 0 0.2em;
    border: none;
    background-color: rgba(205, 205, 205, 0.6);
    transform: translateY(-50%);
    z-index: 2;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}