.TextBlock {
  padding: 1em;

  &--darker {
    background-color: #efefef;
  }

  &--bigger-padding {
    padding: 1.5em 6em;
    background-color: #efefef;

    @media (max-width: 920px) {
      padding: 1.5em 3em;
    }

    @media (max-width: 600px) {
      padding: 2em 1em;
    }
  }

  &__title {
    width: 60%;
    font-family: 'Noto Sans JP', sans-serif;

    & > h2 {
      margin-top: 0;
      margin-bottom: 0.5em;
      font-size: 1.8em;
      font-weight: 200;
    }

    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  &__text {
    width: 60%;
    text-indent: 1.2em;
    font-family: 'Noto Sans JP', sans-serif;

    & :first-child {
      text-indent: 0;
    }

    & > p {
      margin: 0 0 0.2em;
      font-size: 1.2em;
      font-weight: 200;
    }

    @media (max-width: 1100px) {
      width: 100%;
    }
  }
}